// src/components/tally-embed.tsx
"use client";

import React, { useEffect } from 'react';

const TallyEmbed: React.FC = () => {
  useEffect(() => {
    const d = document;
    const w = "https://tally.so/widgets/embed.js";
    const v = () => {
      if (typeof (window as any).Tally !== "undefined") {
        (window as any).Tally.loadEmbeds();
      } else {
        d.querySelectorAll<HTMLIFrameElement>("iframe[data-tally-src]:not([src])").forEach((e) => {
          e.src = e.dataset.tallySrc!;
        });
      }
    };

    if (typeof (window as any).Tally !== "undefined") {
      v();
    } else if (d.querySelector(`script[src="${w}"]`) == null) {
      const s = d.createElement("script");
      s.src = w;
      s.onload = v;
      s.onerror = v;
      d.body.appendChild(s);
    }
  }, []);

  return (
    <iframe
      data-tally-src="https://tally.so/embed/w4jyNk?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
      loading="lazy"
      width="100%"
      height="854"
      frameBorder="0"
      marginHeight={0}
      marginWidth={0}
      title="Report Abuse"
    ></iframe>
  );
};

export default TallyEmbed;
